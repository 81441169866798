import './Spinner.scss';
import loader from '../../../assets/images/loader_easy_pay1.gif'

const Spinner = ()=> {
    return (
        <div className="spinner-container">
            <div className='loader'>
                <img src={loader} alt="easypay-loader" id="easypay-loader"/>
            </div>
            {/* <div className="progress"></div> */}
        </div>
        
    );
}

export default Spinner;