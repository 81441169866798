import React, { Suspense, useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import Home from "./components/home/Home";
import Baas from "./components/Login/Baas";
import Spinner from "./components/Common/Loading/Spinner";
// import { ToastContainer, toast } from "react-toastify";

import Favicon from "../src/assets/images/favicon.ico";
//Screens
const Login = React.lazy(() =>
  import("./components/Login/CredentialsFolder/Login")
);

//Pages
const HomePage = React.lazy(() =>
  import("./components/home/pages/HomePage/HomePage")
);
const ContactUs = React.lazy(() =>
  import("./components/home/pages/ContactUs/ContactUs")
);
const Faq = React.lazy(() => import("./components/home/pages/Faq/Faq"));

const Blog = React.lazy(() => import("./components/home/pages/Blog/Blog"));

const Benefits = React.lazy(() =>
  import("./components/home/pages/Benefits/Benefits")
);

const Challenges = React.lazy(() =>
  import("./components/home/pages/Challenges/Challenges")
);

function setFavicon(imageUrl) {
  const head = document.querySelector("head");
  const favicon = document.createElement("link");
  favicon.rel = "shortcut icon";
  favicon.type = "image/x-icon";
  favicon.href = imageUrl;
  head.appendChild(favicon);
}

function App() {
  const isLoggedIn = sessionStorage.getItem("logitStatus");
  console.log("isLoggedIn: ", isLoggedIn);

  useEffect(() => {
    document.title = "Menafunding";
    const imageUrl = Favicon;
    setFavicon(imageUrl);
  }, []);

  return (
    <>
      <Suspense fallback={<Spinner />}>
        <Routes>
          <Route
            path=""
            element={
              <Navigate replace to={isLoggedIn ? "home" : "menafunding"} />
            }
          />
          {isLoggedIn && (
            <Route path="" element={<Home />}>
              <Route path="home" element={<HomePage />} />
              <Route path="/contactUs" element={<ContactUs />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/blog" element={<Blog />} />
              <Route path="/challenges" element={<Challenges />} />
              <Route path="/benefits" element={<Benefits />} />
            </Route>
          )}
          {!isLoggedIn && (
            <Route path="menafunding" element={<Baas />}>
              <Route
                path="/menafunding"
                element={<Navigate replace to="login" />}
              />
              <Route path="login" element={<Login />} />
            </Route>
          )}
          <Route path="*" element={<Navigate replace to={"/"} />} />
        </Routes>
        {/* <ToastContainer /> */}
      </Suspense>
    </>
  );
}
export default App;
