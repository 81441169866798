import React, { useCallback, useEffect, useState } from "react";
import "./Topnav.scss";
import { useNavigate } from "react-router-dom";
import { RiMenu2Line } from "react-icons/ri";
import { BiFullscreen, BiSun, BiMoon } from "react-icons/bi";
import { MdFullscreen } from "react-icons/md";
import bblack from "../../../assets/images/bblack.png";
import bwhite from "../../../assets/images/bwhite.png";
import { IoIosArrowDown } from "react-icons/io";
//import BellNotification from "./BellNotification/BellNotification";

const Topnav = ({ sendData }) => {
  const navigate = useNavigate()
  const [fullScreen, setFullScreen] = useState(true);
  const [myTheme, setMyTheme] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    sendData(myTheme);
  });

  const toggleSidenav = () => {
    const rightNav = document.getElementById("sidenav");
    rightNav.classList.toggle("right-nav");
  };

  const addIconsOnlyClass = () => {
    const list = document.body.classList;
    list.toggle("sidenav-icon-only");
  };

  window.addEventListener("resize", function () {
    if (window.innerWidth < 992)
      document.body.classList.remove("sidenav-icon-only");
  });

  const openProfileNav = () => {
    const eleClassList = document.getElementById("profile-dropdown").classList;
    eleClassList.toggle("show");
  };

  const fullWindow = useCallback(() => {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.webkitRequestFullscreen) {
      /* Safari */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) {
      /* IE11 */
      elem.msRequestFullscreen();
    }
    setFullScreen(false);
  },[]);

  const smallWindow = useCallback(() => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreen(true);
  },[]);

  const logoutHandler = () => {
    if (localStorage.getItem("theme") === "dark") {
      document.body.classList.remove("dark-theme");
      localStorage.setItem("theme", "light");
    }
    sessionStorage.clear();
    document.title="Menafunding";
    window.location.reload()
    navigate("/menafunding/login")
  };

  // Toggle THEME by this function
  const changeTheme = () => {
    document.body.classList.toggle("dark-theme");

    // Toggle theme value in local storage
    if (localStorage.getItem("theme") === "light") {
      localStorage.setItem("theme", "dark");
      setMyTheme("dark");
    } else {
      localStorage.setItem("theme", "light");
      setMyTheme("light");
    }
  };

  //stay on same theme after refressh or on reopening of website
  if (localStorage.getItem("theme") === "light") {
    document.body.classList.remove("dark-theme");
  } else if (localStorage.getItem("theme") === "dark") {
    document.body.classList.add("dark-theme");
  } else {
    //this code will be executed when user visit website for first time
    localStorage.setItem("theme", "light");
    setMyTheme("light");
  }

  window.addEventListener("click", (event) => {
    if (!event.target.closest(".profile-link")) {
      const profileContainer = document.getElementById("profile-dropdown");
      if(profileContainer) {
        if (profileContainer.classList.contains("show")) {
          profileContainer.classList.remove("show");
        }
      }
    }
  });

  return (
    <nav className="top-navbar fixed-top d-flex">
      <div className="top-navbar-brand-wrapper d-flex d-lg-none align-items-center justify-content-center">
        <a className="top-navbar-brand brand-logo-mini" href="index.html">
          {myTheme === "light" ? (
            <img height="28" src={bblack} alt="mini-title" />
          ) : (
            <img height="28" src={bwhite} alt="mini-title" />
          )}
        </a>
      </div>
      <div className="top-navbar-menu-wrapper d-flex justify-content-between align-items-center">
        <div>
          <button className="navbar-toggler" onClick={addIconsOnlyClass}>
            <RiMenu2Line className="hamburger" />
          </button>
        </div>
        <ul className="nav justify-content-center align-items-center">
          {/* <li className="nav-item">
            <div className="nav-link radius" onClick={changeTheme}>
              <span className="menu-icon">
                {myTheme === "light" ? <BiMoon /> : <BiSun />}
              </span>
              <span className="menu-title"></span>
            </div>
          </li> */}
          <li className="nav-item">
            <div className="nav-link radius">
              <span className="menu-icon">
                {fullScreen ? (
                  <BiFullscreen onClick={fullWindow} />
                ) : (
                  <MdFullscreen onClick={smallWindow} />
                )}
              </span>
              <span className="menu-title"></span>
            </div>
          </li>
          <li className="nav-item position-relative" onClick={openProfileNav}>
            <div className="nav-link profile-link" href="#">
              <span className="menu-title">
                My Account
                <span id="down-arrow" className="arrow">
                  <IoIosArrowDown />
                </span>
              </span>
            </div>
            <div id="profile-dropdown" className="position-absolute end-0">
              <div className="p-drop-item">
                <span
                  onClick={logoutHandler}
                  className="menu-title my-logout-color"
                >
                  Logout
                </span>
              </div>
            </div>
          </li>
          <li className="nav-item right-nav-toggler">
            <div className="nav-link" onClick={toggleSidenav}>
              <RiMenu2Line />
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Topnav;
