import './Footer.scss';
import menaLogo from "../../../assets/images/menafunding/menaLogo.jpg";

const Footer = ({myTheme})=> {

    return (
        <footer className="footer-bottom d-flex justify-content-end align-items-center">
            <div className="footer-site-info">
                Powered By 
                <img 
                    alt='easypay-logo' 
                    src={myTheme === "light" ? menaLogo : menaLogo} height="68"
                /> 
                © 2024
            </div>
        </footer>
    );
}

export default Footer;