import React, { Fragment } from "react";
import "./Sidenav.scss";
import dashboard64 from "../../../assets/images/icons/dashboard/dashboard64.png";
import { NavLink, Link } from "react-router-dom";

import userLogo from "../../../assets/images/menafunding/menaLogo.jpg";

const Sidenav = () => {

  // Close all sidenav submenu when clicked outside
  window.addEventListener("click", (event) => {
    let sidenav = document.getElementById("sidenav");
    if (sidenav) {
      if (!event.target.closest(".nav-area")) {
        navArea();
      }
      if (!event.target.closest(".right-nav-toggler")) {
        sideNavArea(sidenav, event);
      }
    }
  });

  const navArea = () => {
    let submenus = document.getElementsByClassName("sub-menu-content");
    for (const element of submenus) {
      let openSubmenu = element;
      if (openSubmenu.classList.contains("show")) {
        openSubmenu.classList.remove("show");
      }
    }
  };

  const sideNavArea = (sidenav, event) => {
    if (!event.target.closest(".open-submenu-btn")) {
      if (sidenav.classList.contains("right-nav")) {
        sidenav.classList.remove("right-nav");
      }
    }
  };

  return (
    <Fragment>
      <nav id="sidenav">
        <div className="sidenav-brand-wrapper fixed-top d-none d-lg-flex justify-content-center align-items-center">
          <Link to="/home">
            <div className="brand-logo">
              <img height="50" src={userLogo} alt="logo" />
            </div>
          </Link>
        </div>
        <ul className="navbar-nav nav-area">
          <li className="nav-item">
            <NavLink to="/blog" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">Blog</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/contactUs" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">ContactUs</span>
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/faq" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">FAQ</span>
            </NavLink>
          </li>
          {/* <li className="nav-item">
            <NavLink to="/challenges" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">Challenges</span>
            </NavLink>
          </li> */}
          <li className="nav-item">
            <NavLink to="/benefits" className="nav-link">
              <span className="menu-icon">
                <img width="20" height="20" src={dashboard64} alt="" />
              </span>
              <span className="menu-title">Benefits</span>
            </NavLink>
          </li>
        </ul>
      </nav>
    </Fragment>
  );
};

export default Sidenav;
