import React, {useState} from "react"

const AuthContext = React.createContext({
    token: '',
    isLoggedIn: false,
    login: (token) => {},
    logOut: ()=> {}
});

const calculateRemainingTime = (expirationTime) => {
    const currentTime = new Date().getTime();
    const adjExpirationTime = new Date(expirationTime).getTime();

    const remainingDuration = adjExpirationTime - currentTime;

    return remainingDuration;
};

export const AuthContextProvider = (props) => {
    const initialToken = sessionStorage.getItem('newToken');
    const [token, setToken] = useState(initialToken);
    const userIsLoggedIn = !!token;

    const logoutHandler = () => {
        setToken(null);
        sessionStorage.removeItem('newToken');
        sessionStorage.removeItem('cpId');
        sessionStorage.removeItem('cpMstCode');
        localStorage.removeItem('tokenValue');
        const user = sessionStorage.getItem('userName');
        localStorage.removeItem(user);
    };

    const warningLogoutHandler = () => {
        alert("Your session expired, Please login again");
        logoutHandler();
    }

    const loginHandler = (token, expirationTime) => {
        setToken(token);
        sessionStorage.setItem('newToken', token);

        const remainingTime = calculateRemainingTime(expirationTime);
        setTimeout(warningLogoutHandler, remainingTime);
    };

    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        login: loginHandler,
        logOut: logoutHandler
    };
    

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    );
};

export default AuthContext;