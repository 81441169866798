import React, { useRef } from 'react'
import "./Header.scss"
import Logo from "../../../assets/images/menafunding/menaLogo.jpg";
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation()
  console.log("location",location);
    const headerRef = useRef(null);
     // Animated Header
  const changeHeader = () => {
    if (window.scrollY > 10) {
      headerRef.current &&
        (headerRef.current.style.boxShadow =
          "0px 1px 6px 0px rgba(32, 33, 36, 0.28)");
    } else {
      headerRef.current && (headerRef.current.style.boxShadow = "none");
    }
  };
  window.addEventListener("scroll", changeHeader);


  return (
    <header id="header" ref={headerRef}>
        <a className="sell-link" href="index.html">
          <img src={Logo} alt="easypay-logo" className="easypay-logo" />
        </a>
      </header>
  )
}

export default Header